import { LinkSquare16Filled } from "@fluentui/react-icons";

import styles from "./ProductList.module.css";
import { useState, useEffect } from "react";
import productsFile from "../../assets/products.txt";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";

interface Props {
    //productList: Array<Product>;
    useFile: boolean;
}

export interface Product {
    name: string;
    link?: string;
}

export const ProductList = ({ useFile }: Props) => {
    const [openItems, setOpenItems] = useState(["1"]);
    const handleToggle: AccordionToggleEventHandler<string> = () => {
        if (openItems.length > 0) {
            setOpenItems([]);
        } else {
            setOpenItems(["1"]);
        }
    };
    const [closed, setClosed] = useState<boolean>(false);
    const [products, setProducts] = useState<Array<Product>>([]);
    if (productsFile) {
        useEffect(() => {
            fetch(productsFile).then(response => {
                response.text().then(text => {
                    console.log("here");
                    let productList: Array<Product> = [];
                    const lines = text.split("\n");
                    for (let i = 0; i < lines.length; i++) {
                        const line = lines[i];
                        const parts = line.split("|");
                        if (parts.length === 2) {
                            productList.push({ name: parts[0], link: parts[1] });
                        } else {
                            productList.push({ name: line });
                        }
                    }
                    setProducts(productList);
                });
            });
        }, []);
    }

    const accordionProductListItems = products.map(product => {
        if (product.link) {
            return (
                <li key={product.name} className={styles.accLiItemLink}>
                    <a href={product.link} target="_blank">
                        {product.name}
                    </a>
                    <LinkSquare16Filled primaryFill="rgba(0, 0, 100, 0.5)" />
                </li>
            );
        } else {
            return (
                <li className={styles.accLiItem} key={product.name}>
                    {product.name}
                </li>
            );
        }
    });
    if (closed) {
        return null;
    }
    return (
        <div className={styles.productList}>
            <button className={styles.close} onClick={() => setClosed(true)}></button>
            <Accordion collapsible openItems={openItems} onToggle={handleToggle}>
                <AccordionItem value="1">
                    <AccordionHeader>
                        <h3 className={styles.accHeader}> Product list</h3>
                    </AccordionHeader>
                    <AccordionPanel className={styles.panel}>
                        <ol className={styles.accList}>{accordionProductListItems}</ol>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};
